@import url('https://fonts.googleapis.com/css2?family=Space+Grotesk:wght@300;400;500;600;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap');
/*@font-face {
  font-family: 'Helvetica Now Text';
  src: url('./fonts/HelveticaNowText-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Helvetica Now Text';
  src: url('./fonts/HelveticaNowText-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}*/

.App {
  /*font-family: "Helvetica Now Text", sans-serif;*/
  font-family: "Inter", "Helvetica", sans-serif;
  /*font-family: "Space Grotesk", sans-serif;*/
  line-height: 1.25;
  max-width: 700pt;
  margin: 0 auto;
  padding: 20px;
  box-sizing: border-box;
  /* text-align: center; */
}

h1 {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 30pt;
  font-family: "Space Grotesk";
}

a {
  color: #ff7f50;
}

a:visited {
  color: #ff7f50;
}

.chat-container {
  /* background-color: #f5f5f5; */
  /* padding: 20px; */
  overflow-y: auto;
  /* height: 500px; */
  box-sizing: border-box;
  position: relative;
  /* margin-bottom: 20px; */
  margin-top: 20px;
}

.chat-message {
  padding: 10px 15px;
  margin-bottom: 10px;
  display: table;
  max-width: 70%;
  /* width: auto; */
  word-wrap: break-word;
  border-radius: 20px;
  box-sizing: border-box;
  font-size: 16pt;
}

.chat-message.user {
  background-color: #f0f0f0;
  /* margin-right: 0; */
  /* margin-left: auto; */
  font-size: 12pt;
  font-weight: 400;
}

.chat-message.error {
  background-color: #ffd8d8;
  max-width: 100%;
  /* margin-right: 0; */
  /* margin-left: auto; */
  font-size: 12pt;
  font-weight: 400;
  animation: fadein 1s;
}

@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}
.chat-message img {
  max-width: 100%;
  display: block;
  margin: 30px 0;
}

.chat-message.assistant {
  /* background-color: #e6e6e6; */
  /* margin-right: 0; */
  /* padding: 0; */
  /* margin-left: auto; */
  font-weight: 600;
  min-width: 300px;
}

.chat-message.system {
  display: none;
}

.chat-input {
  display: block;
  width: 100%;
  margin-bottom: 20px;
}

.chat-input input {
  width: 100%;
  /* padding: 10px 15px; */
  /* border: 1px solid #ccc; */
  /* box-shadow: 0 0 6px 0 darkgray; */
  padding-left: 15px;
  border-radius: 20px;
  box-sizing: border-box;
  border: 0;
  font-family: inherit;
  font-size: inherit;
  /* margin-bottom: 10px; */
}

.chat-input input:focus {
  border: 0;
  outline: none!important;
  caret-color: blue;
}

.preset-buttons {
  display: block;
}

.preset-buttons button {
  /*background-color: #f0f0f0;*/
  /* color: white; */
  /*padding: 10px 15px;*/
  border: none;
  /* border-radius: 20px; */
  margin-bottom: 10px;
  margin-right: 10px;
  cursor: pointer;
  text-align: left;
  max-width: 100%;
  /*font-weight: 500;
  font-size: 12pt;*/
  font-family: inherit;
  display: inline-block;
  /* flex-grow: 1; */
  /* margin-left: 5px; */
  /* margin-right: 5px; */
  /* text-align: center; */
}

.preset-buttons button:hover {
  background-color: #cfcfcf;
  transition: 0.25s;
}

sub {
  /* margin-left: 10px; */
  color: #f0f0f0;
  transition: 0.5s;
}

sub:hover {
  color: #9f9f9f;
  transition: 0.5s;
}

.loading-indicator {
  max-width: 35px;
  height: 16pt;
  /* display: inline-block; */
  margin-left: 5px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.grecaptcha-badge {
  display: none!important;
}