.cursor {
    background-color: #000;
    width: 2px;
    height: 1em;
    display: inline-block;
    margin-left: 2px;
    animation: blink 1s steps(1) infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}
